import React from "react";

export default function About(props) {
  // const [myStyle, setMyStyle] = useState({
  //     backgroundColor: "white",
  //     color:'black'
  // })
  // const toggleStyle = () => {
  //     if(myStyle.color === "white")
  //     {
  //         setMyStyle({
  //             color:'#333333',
  //             backgroundColor:'#ffffff'
  //         })
  //     }else{
  //         setMyStyle({
  //             color:'#ffffff',
  //             backgroundColor:'#333333',
  //         })
  //     }
  // }
  return (
    <div
      style={{
        backgroundColor: props.mode === "light" ? "white" : "#2C3333",
        color: props.mode === "light" ? "black" : "white",
      }}
    >
      <div
        className="container"
        style={{
          backgroundColor: props.mode === "light" ? "white" : "#2C3333",
          color: props.mode === "light" ? "black" : "white",
        }}
      >
        <h1 className="my-3">About Us</h1>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                style={{
                  backgroundColor:
                    props.mode === "light" ? "#E7F6F2" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <strong>Upper Case</strong>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse  show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div
                className="accordion-body"
                style={{
                  backgroundColor: props.mode === "light" ? "white" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
              >
                The upper case transformer will take any text that you have and
                will generate all the letters into upper case ones. It will
                essentially make all lower case letters into CAPITALS (as well
                as keep upper case letters as upper case letters). To do this,
                you simply have to select the text that you need changing and
                pasting into the box above and then select the UPPER CASE tab.
                THIS IS AN EXAMPLE OF UPPER CASE.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                style={{
                  backgroundColor:
                    props.mode === "light" ? "#E7F6F2" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <strong>Lower Case</strong>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div
                className="accordion-body"
                style={{
                  backgroundColor: props.mode === "light" ? "white" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
              >
                If you are wondering how to uncapitalize text, this is exactly
                what the lower case text converter will allow you to do - it
                transforms all the letters in your text into lowercase letters.
                Simply copy the text that you need generating into lower case
                and paste the text into the box above and select the ‘lower
                case’ tab.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                style={{
                  backgroundColor:
                    props.mode === "light" ? "#E7F6F2" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <strong>Capitalized Case</strong>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse "
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div
                className="accordion-body"
                style={{
                  backgroundColor: props.mode === "light" ? "white" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
              >
                The capitalized case converter will automatically convert the
                starting letter of every word into an upper case and will leave
                the remaining letters as lower case ones.Simply copy the content
                that you will like to generate into this format, then paste into
                the box form above and select the Capitalized Case tab.This Is
                An Example Of Capitalized Case.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                style={{
                  backgroundColor:
                    props.mode === "light" ? "#E7F6F2" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <strong>Free to Use</strong>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse "
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div
                className="accordion-body"
                style={{
                  backgroundColor: props.mode === "light" ? "white" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
              >
               TextUtils is a free Character counter tool that provides instant Character count and word count and statistics of a given text. TextUtils reports the number of words and characters. Thus, it is suitable for writing text with word/character limit.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                style={{
                  backgroundColor:
                    props.mode === "light" ? "#E7F6F2" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <strong>Browser Compatible</strong>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse "
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div
                className="accordion-body"
                style={{
                  backgroundColor: props.mode === "light" ? "white" : "#2C3333",
                  color: props.mode === "light" ? "black" : "white",
                }}
              >
                This word counter software works in every browser such as Chrome, Brave, Firefox, Internet Explorer, Safari, Opera. It suits to count characters in facebook, blog, books, excel document, pdf document, essays, etc.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
