import "./App.css";
import Navbar from "./Components/Navbar";
import TextForm from "./Components/TextForm";
import React, { useState } from "react";
import Alert from "./Components/Alert";
import About from "./Components/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [mode, setMode] = useState("light");
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  };

  function applyTransition() {
    const root = document.documentElement;
    const body = document.body;
    const transitionDuration = "0.5s";

    root.style.setProperty("--transition-duration", transitionDuration);

    body.style.transition = `all ${transitionDuration}`;
  }

  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "#2C3333";
      showAlert("Dark Mode has been Enabled!", "success");
      applyTransition();
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
      showAlert("Light Mode has been Enabled!", "success");
      applyTransition();
    }
  };
  return (
    <Router>
        <Navbar title="TextUtils" mode={mode} toggleMode={toggleMode} />
        <Alert alert={alert} />
        <div className="container my-3">
          <Routes>
            <Route exact path="/about" element={<About mode={mode}/>}>
            </Route>
            <Route exact path="/" element={<TextForm heading = "Try TextUtils - Word Counter, Character Counter " showAlert={showAlert} mode={mode}/>}>
            </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
