import React , { useState } from 'react'


export default function TextForm(props) {
    const handleUpClick = () => {
        // console.log("uppercase" + text) // jsut used to debug 
        let newText = text.toUpperCase();
        setText(newText)
        // props.showAlert("Converted to Uppercase !" , "success")
    }
    const handleLowClick = () => {
        let newText = text.toLocaleLowerCase()
        setText(newText)
        // props.showAlert("Converted to Lowercase !" , "success")
    }
    const handleCapital = () => {
        const words = text.split(" ");
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let newText =  capitalizedWords.join(" ");
        setText(newText)
        // props.showAlert("Capitalized the Text !" , "success")
    }
    const handleExtraSpace = () => {
        let newText =  text.trim().split(/ +/).join(' ');
        setText(newText)
        // props.showAlert("Extra spaces are removed !" , "success")
    }
    const handlecopyText = () => {
        let newText = document.getElementById("myBox")
        newText.select();
        navigator.clipboard.writeText(newText.value)
        props.showAlert("Text Copied to Clipboard !" , "success")
    }
    const handleClearClick = () => {
        setText("")
        // props.showAlert("Text Cleared !" , "success")
    }
    const handleOnChange = (event) => {
        // console.log("On Change")
        setText(event.target.value)
    }
    const [text , setText] = useState("")
    // text = "new text" // wrong way to set text
    // setText("new text") // correct way to set text
    return (
        <>
        <div className='container' style={{color: props.mode === 'light' ? 'black' : 'white' }}>
            <h1 className='mb-4'>{props.heading}</h1>
            <div className="mb-3">
                <textarea className="form-control" value={text} onChange={handleOnChange} style={{backgroundColor: props.mode === 'light' ? "#E7F6F2" : "#2C3333" , color: props.mode === 'light' ? 'black' : 'white' }} id="myBox" rows="8"></textarea>
            </div>
                <button className="btn btn-secondary mx-1 my-2" onClick={handleUpClick}>Convert to Uppercase</button>
                <button className="btn btn-secondary mx-1 my-2" onClick={handleLowClick}>Convert to Lowercase</button>
                <button className="btn btn-secondary mx-1 my-2" onClick={handleCapital}>Capitalize Text</button>
                <button className="btn btn-secondary mx-1 my-2" onClick={handleExtraSpace}>Remove Extra Spaces</button>
                <button className="btn btn-secondary mx-1 my-2" onClick={handlecopyText}>Copy Text</button>
                <button className="btn btn-secondary mx-1 my-2" onClick={handleClearClick}>Clear Text</button>
        </div>
        <div className="container my-2" style={{color: props.mode === 'light' ? 'black' : 'white' }}>
            <h2>Your Text Summary: </h2>
            <p> Words: {text.split(/\s+/).filter((element) => {return element.length !== 0}).length} </p>
            <p> Characters: {text.length} </p>
            <p> Time to read the whole text: {0.008 * text.split(" ").filter((element) => {return element.length !== 0}).length } minutes</p>
            <h3>Preview of your text </h3>
            <p>{text.length>0 ? text:"Nothing to preview yet . . . "}</p>
        </div>
        </>
    )
}
/*
  You can ignore these comments , these are just for some basic knowledge
  bina class banaaye class k features ko access krne me help krta h hooks
  we import hooks from react {useState}
  and use them like :
  const[count, setCount] = useState(0)
  hamesa function component k ander use krna h isko
  setCount k ander count ki value set krte h 
  state ko update krne k liye fncn k ander aise use krte hai
  we can't just update them like simple variables

  In our case here in fncn text ki value "Enter the text here" hai
  iski value jab change hogi to vo setText me hogi 
  like:
  setText("Iski value change hogi")
  now the value of text is "Iski value change hogi"
*/